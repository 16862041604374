(function ($) {
  /*
   * Conceptually, the mpp sorter should not really know about the grids it is
   * controlling. It is up to the mpp layout or hwatever else to catch the
   * mpp_sort events and coordinate between it and the grids.
   */
  Drupal.behaviors.mppSort = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      var $formatters = $('.mpp-sort__container', context);

      $formatters.each(function (i, obj) {
        var $formatter = $(obj);
        var $sort_links = $('li', $formatter);
        var $label = $('.mpp-sort__label', $formatter);

        // Close dropdown
        function close() {
          $formatter.removeClass('open');
          $('.mpp-sort__label', $formatter).removeClass('open');
          $(document).trigger('mpp-filter-set--close');
        }

        $label.on('click', function (event) {
          event.preventDefault();
          $formatter.toggleClass('open');
          $('.mpp-sort__label', $formatter).toggleClass('open');

          if ($formatter.hasClass('open')) {
            $(document).trigger('mpp-filter-set--open');
            $(document).on('mouseup.clickoutside touchend.clickoutside', function (e) {
              var container = $formatter;

              // if the target of the click isn't the container nor a descendant of the container
              if (!container.is(e.target) && container.has(e.target).length === 0) {
                $('.js-mpp-container').removeClass('sorter-open');
                $('body').removeClass('sorter-open');
                $('body').css('height');
                e.preventDefault();
                close();
                $(document).off('.clickoutside');
              }
            });
          } else {
            close();
          }
        });

        if (!$sort_links.hasClass('active')) {
          $sort_links.first().addClass('active');
        }

        $formatter.on('mpp-sort:reset', function () {
          var $default_link = $sort_links.first();

          $default_link.trigger('click');
        });

        $sort_links.each(function (i, obj) {
          var $link = $(obj);

          $link.on('click', function (e) {
            e.preventDefault();

            $sort_links.removeClass('active');
            $link.addClass('active');

            var val = $link.data('sort-key');

            $formatter.trigger('mpp-sort:selected', val);
          });
        });
      });
      this.attached = true;
    }
  };
})(jQuery);
